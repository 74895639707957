import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyC_KWldJcilIMqwOu-_QQfJ9gBKm96Zvqo",
  authDomain: "hirt-fotos.firebaseapp.com",
  projectId: "hirt-fotos",
  storageBucket: "hirt-fotos.appspot.com",
  messagingSenderId: "109628480138",
  appId: "1:109628480138:web:09206c4ead87c82ab16b73",
  measurementId: "G-9STFDZ9K9Z"
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);