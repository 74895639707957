import { Button, FormControl, InputLabel, Select } from "@mui/material";
import axios from "axios";
import { useState, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../Constants/url";
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import MenuItem from '@mui/material/MenuItem';
import { useProtectedPage } from "../../Hooks/useProtectedPage";
import {
    ContainerCard, ContainerChecklist, ContainerGeral, FormFoto, InputFoto, ContainerUpload,
    InputChecklist, Image
} from "../../Styled/StyledCollab/StyledEditAp";
import { goToCollabPage } from "../../Routes/RouteFunctions";
import { Header } from "../../Constants/Header";
import { ImageGrid } from "../../Styled/StyledCollab/StyledConcludedAp";
import Swal from "sweetalert2";



export const EditApartment = () => {
    useProtectedPage();
    const filesElement = useRef(null);
    const { ap_id } = useParams();
    console.log(ap_id)
    const navigate = useNavigate();
    const [conclusion, setConclusion] = useState('');
    const [image, setImage] = useState([]);
    const user_id = localStorage.getItem('id')
    const [caxilho, setCaxilho] = useState(false);
    const [paredes, setParedes] = useState(false);
    const [pisos, setPisos] = useState(false);
    const [tomadas, setTomadas] = useState(false);
    const [banheiros, setBanheiros] = useState(false);
    const [sacadas, setSacadas] = useState(false);
    const [bancadas, setBancadas] = useState(false);
    const [tetos, setTetos] = useState(false);
    const [portas, setPortas] = useState(false);
    const [loading, setLoading] = useState(false)


    const onSelectedFiles = (event) => {
        const selectedFiles = event.target.files;
        const selectedFilesArray = Array.from(selectedFiles);

        const imageArray = selectedFilesArray.map((file) => {
            return URL.createObjectURL(file)
        })
        setImage(imageArray)
    };

    const uploadImage = async (e) => {
        setLoading(true)
        e.preventDefault()
        const token = localStorage.getItem("token");
        const dataForm = new FormData();

        let file = []

        for (const file2 of filesElement.current.files) {

            dataForm.append('avatar', file2);
            dataForm.append('limpeza_completa', conclusion);
            dataForm.append('caxilho', caxilho);
            dataForm.append('paredes', paredes);
            dataForm.append('pisos', pisos);
            dataForm.append('tomadas', tomadas);
            dataForm.append('tetos', tetos);
            dataForm.append('banheiros', banheiros);
            dataForm.append('portas', portas);
            dataForm.append('bancadas', bancadas);
            dataForm.append('sacadas', sacadas);
            file = dataForm
        }


        axios.put(`${BASE_URL}/apartments/editAp/${ap_id}`, dataForm, {
            headers: {
                authorization: token,
                ContentType: "multipart/form-data"
            }
        }).then((response) => {
            setLoading(false);
            Swal.fire({
               title: "Informações Salvas!"
            });
            console.log(response.data)
            goToCollabPage(navigate('collab', user_id))


        }).catch(error => {
            setLoading(false)
            console.log(error)
            Swal.fire({
                title: "Deu erro, tente novamente!"
             });
        });

    };

    const listaImg = () => {
        return <ContainerCard>
            <ContainerChecklist>
                <label>
                    <InputChecklist type='checkbox' onClick={() => setCaxilho(true)} />Caxilho
                    <InputChecklist type='checkbox' onClick={() => setParedes(true)} />Paredes
                    <InputChecklist type='checkbox' onClick={() => setPisos(true)} />Pisos
                    <InputChecklist type='checkbox' onClick={() => setTomadas(true)} />Tomadas
                    <InputChecklist type='checkbox' onClick={() => setBanheiros(true)} />Banheiros
                    <InputChecklist type='checkbox' onClick={() => setSacadas(true)} />Sacadas
                    <InputChecklist type='checkbox' onClick={() => setBancadas(true)} />Bancadas
                    <InputChecklist type='checkbox' onClick={() => setTetos(true)} />Tetos
                    <InputChecklist type='checkbox' onClick={() => setPortas(true)} />Portas
                </label>
            </ContainerChecklist>

            <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">Limpeza:</InputLabel>
                <Select sx={{ minWidth: 150 }}
                    required
                    labelId="demo-simple-select-label"
                    label="Limpeza"
                    id="demo-simple-select"
                    value={conclusion}
                    onChange={(e) => setConclusion(e.target.value)}
                >
                    <MenuItem value={1}>Limpeza Grossa</MenuItem>
                    <MenuItem value={2}>Limpeza Fina</MenuItem>
                    <MenuItem value={3}>Entrega</MenuItem>
                </Select>
            </FormControl>

            <FormFoto encType="multipart/form-data" onSubmit={uploadImage}>

                    <input type={"file"} multiple ref={filesElement} name="avatar" onChange={onSelectedFiles} />
                    <ContainerUpload>
                        {image ? <ImageGrid> {image && image.map((imagem) => {
                            return <div>
                                <Image src={imagem} ></Image>
                            </div>
                        })}</ImageGrid> : "adicione uma foto"}
                    </ContainerUpload>
                    <Button variant="contained" endIcon={<SendIcon />} type={"submit"}>Salvar</Button>
                
            </FormFoto>
        </ContainerCard >
    };

    return (
        <ContainerGeral>
            <Header />
            <Button variant="contained" startIcon={<ArrowBackIosIcon />} onClick={() => goToCollabPage(navigate, 'collab', user_id)}>Voltar</Button>
            {/* {!loading && erro && } */}
            {/* {!loading &&  */}
            {listaImg()}
            {/* } */}
            {/* {loading && loading &&
                <CircularProgress sx={{ color: '#4498C6ff' }} spacing={4} />} */}
        </ContainerGeral>


    )
};