import styled from "styled-components";
import { device } from "../../Constants/Query";

export const ContainerGeral = styled.div`
  width: 100%;
  min-height: auto;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 1em;
  position: absolute;
  `

export const AlinhaSaudacao = styled.div`
    color: #0000ff;
    font-family: 'Open Sans';
    font-weight: 800;
    font-size: 2em;
`

export const ContainerLogout = styled.div`
    display: flex;
    flex-direction: row;
    gap: 7rem;
    margin-top: 1rem;
`

export const ContainerGrid = styled.div`
    margin: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5rem;

    @media ${device.mobileS} {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin: 0rem;
    }

    @media ${device.tablet} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.laptop} {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
`

export const CardObras = styled.div`
    width: 90%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 5px #d3d3d3;
    /* border: 0.1rem solid #c1c1c3 ; */
    /* border-bottom: hidden;
    border-left: hidden;
    border-top: hidden; */
    padding: 1rem;
    background: #f9f9f9;

`

export const ContainerText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem;
    padding: 0.5rem;
`

export const ButtonsInCard = styled.div`
    display: flex;
    justify-content: space-evenly;
    cursor: pointer;
`