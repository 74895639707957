import styled from "styled-components";
import { device } from "../../Constants/Query";

export const ContainerGeral = styled.div`
  min-width: 100%;
  min-height: 100vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 1em;

  @media ${device.mobileS}{
        margin-top: 2rem;
 }

  @media ${device.laptop}{
        margin-top: 2rem;
 }

  @media ${device.laptopL}{
    justify-content: space-around;
}
`

export const ContainerCard = styled.form`
    margin-top: 0.75rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    box-shadow: 2px 2px 2px 2px #d3d3d3;
    padding: 2.5rem;
    gap: 0.5rem;
    background: #f9f9f9;
    align-items: center;
    justify-content: center;

    @media ${device.mobileS}{
        margin-top: 2rem;
        padding: 1rem;
        width: 70%;
    }

    @media ${device.laptop}{
        margin-top: 2rem;
        width: 60%;
        padding: 2.5rem;
    }
`
export const LabelArquivo = styled.label `
    width: 12rem;
    length: 10rem; 
    border-radius: 10px;
    border: #ccc solid;
    /* background-color: #d3d3d3; */
    padding: 10px 5px;
    text-align: center;
    margin: 1rem;
`

export const SomeInput = styled.input `
    display: none;
 
  `
  
export const Input = styled.input`
        display:none;
`

export const DivLabel = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 2rem;
`
// export const ContainerUpload = styled.form`
// label{
//     justify-content: center;
// }
//     @media ${device.mobileS}{
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         width: 80%;
//     }

//     @media ${device.laptop}{
//         display: flex;
//         flex-direction: row;
//         justify-content: center;
//         width: 90%;
//     }
// `

export const ContainerChecklist = styled.div`
    display: flex;
    gap: 0.8rem;
    font-family: 'Roboto';
    font-weight: 500;
    margin: 0.5rem;
    color: #485577ff;

    @media ${device.mobileS}{
        width: 75%;
        margin-bottom: 0.8rem;
        padding: 0.2rem;
        font-size: 1rem;
    }

    @media ${device.mobileM}{
        width: 66%;
    }

    @media ${device.mobileL}{
        width: 90%;
        font-size: 1rem;
    }

    @media ${device.tablet}{
        display: flex; 
        flex-direction: row;
        width: 95%;
      }

    @media ${device.laptop}{
        display: flex;
        padding: 1.5rem;
        width: 100%;
    }
`

export const InputChecklist = styled.input`
    margin: 0.5rem;
`

export const FormFoto = styled.form`


   @media ${device.mobileS}{
        display: flex;
        flex-direction: column;
        width: 100%;

        button{
            margin-left: 0%;
        }

        input{
            margin-left: 0%;
            margin-bottom: 5%;
            margin-top: 5%;
        }
    }

    @media ${device.tablet}{
        button{
            margin-left: 40%;
            width: 20%;
        }
        input{
            margin-left: 25%;
            margin-bottom: 5%;
            margin-top: 5%;
        }
    }

    @media ${device.laptop}{
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 2rem;

        button{
            margin-left: 40%;
        }

        input{
            margin-top: 1rem;
            margin-left: 30%;
        }
    }
`

export const Image = styled.img`
    max-width:10rem;
    max-height:20rem;
    width: auto;
    height: auto;
    border-radius: 10px;
    margin: 1rem;
  `

export const ImageGrid = styled.div`
    gap: 0.8rem;
 
    @media ${device.mobileS}{
      display: flex;
      flex-direction: column;
    }

    @media ${device.tablet}{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

}
`
