import styled from "styled-components";
import { device } from "../../Constants/Query";

export const ContainerGeral = styled.div`
  min-width: 100%;
  min-height: 96vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 1em;

  @media ${device.mobileM} {
        row-gap: 2rem;
        justify-content:  flex-start;
        padding: 1rem;
    }
`

export const ContainerCard = styled.form`
    width: 50%;
    display: flex;
    flex-direction: column;
    box-shadow: 10px 5px 5px #d3d3d3;
    border-radius: 1rem;
    padding: 2.5rem;
    gap: 0.5rem;
    background: #f9f9f9;
    align-items: center;
    row-gap: 1rem;

    @media ${device.mobileM} {
        row-gap: 2rem;
        padding: 1rem;
    }
`