import { useNavigate, useParams } from "react-router-dom";
import useForm from "../../Hooks/useForm";
import { goToAdminPage, goToCollabPage, goToHomePage } from "../../Routes/RouteFunctions"
import { Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import { useState } from "react";
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CircularProgress from '@mui/material/CircularProgress';
import { BASE_URL } from "../../Constants/url";
import axios from "axios";
import { Header } from "../../Constants/Header";
import { ContainerCard, ContainerGeral } from "../../Styled/StyledLogin/StyledSignUp";
import Swal from 'sweetalert2';


export const SignUpPage = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [erro, setErro] = useState('')
    const { type } = useParams()
    const [form, handleInputChange, clear] = useForm({
        nome: "", email: "", password: "", 
        //code: "", {/* INLUI SOLICITAÇÃO DE CÓDIGO PARA SE REGISTRAR */}
        // role: colocar um select novo para o tipo de registro #########
    });

    console.log('form', form);

    const sendForm = event => {
        event.preventDefault();
        // console.log(form)
        axios
            .post(`${BASE_URL}/user/signup`, form,
                {
                    headers: {
                        contentType: "application/json"
                    }
                }
            )
            .then((response) => {
                setLoading(false)
                    clear()
                    // localStorage.setItem('token', response.token)
                    
                    if (type === "admin") {
                        goToAdminPage(navigate, type)
                        //console.log('entrei no if', type)
                    } else {
                        goToCollabPage(navigate, type)
                    }
                
            })
            .catch((error) => {
                setLoading(false)
                setErro(error.message)
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    // footer: '<a href="#">Why do I have this issue?</a>'
                  });
                navigate(-1)
            });
    };

    return (
        <ContainerGeral>
            <Header />
            {!loading && erro && <p>Deu ruim!</p>}
            <Button variant="contained" sx={{background: '#0000FF'}} startIcon={<ArrowBackIosIcon />} onClick={() => goToHomePage(navigate)}>Voltar</Button>

            <ContainerCard onSubmit={sendForm}>
                <TextField fullWidth required
                    id="outlined-required"
                    label="Nome"
                    type={'text'}
                    name={"name"}
                    onChange={handleInputChange}
                    value={form.name}
                />

                <TextField fullWidth required
                    id="outlined-required"
                    label="e-mail"
                    type={"e-mail"}
                    name={"email"}
                    onChange={handleInputChange}
                    value={form.email}
                    placeholder={"email"}
                />

                <TextField fullWidth required
                    id="outlined-required"

                    label="password"
                    name={"password"}
                    type={"password"}
                    onChange={handleInputChange}
                    value={form.password}
                />

                {/* INLUI SOLICITAÇÃO DE CÓDIGO PARA SE REGISTRAR */}
                {/* {type === 'admin' ? <TextField fullWidth required
                    id="outlined-required"

                    label="code"
                    name={"code"}
                    type={"password"}
                    onChange={handleInputChange}
                    value={form.code}
                /> : ""} */}

                <Button variant="contained" endIcon={<SendIcon />} type="submit">Criar</Button>

                {loading && loading &&
                    <CircularProgress sx={{ color: '#4498C6ff' }} spacing={2} />}
            </ContainerCard>
        </ContainerGeral>
    )
};
