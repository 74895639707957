import React from "react";
import logo from "../../images/logo.jpg";
import { goToClientLogin, goToLoginPage } from "../../Routes/RouteFunctions";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ButtonGroup from '@mui/material/ButtonGroup';
import { BotaoSmall, ContainerButton, ContainerCard, ContainerGeral, Image } from "../../Styled/StyledHome/StyledHome";
import { useProtectedPage } from "../../Hooks/useProtectedPage";
import { useProtectedHome } from "../../Hooks/useProtectedHome";

function HomePage() {
    useProtectedHome()

    const navigate = useNavigate();

    return (
        <ContainerGeral>
            <ContainerCard>
                <Image src={logo} alt='logo' />
                <ContainerButton>
                {/* <ButtonGroup variant="contained" > */}
                    <Button variant="text" sx={{fontSize: 16, fontWeight: 800 }} fullWidth onClick={() => goToLoginPage(navigate, 'admin')}>Gestor</Button>
                    <Button variant="text" sx={{fontSize: 16, fontWeight: 800 }} fullWidth onClick={() => goToLoginPage(navigate, 'collab')}>Colaborador</Button>
                    <Button variant="text" sx={{fontSize: 16, fontWeight: 800 }} fullWidth onClick={() => goToClientLogin(navigate, 'client')}>Clientes</Button>
                    {/* </ButtonGroup> */}
                </ContainerButton>
            </ContainerCard>
        </ContainerGeral>
    )
}

export default HomePage;
