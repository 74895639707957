import styled from "styled-components";
import { device } from "../../Constants/Query";

export const ContainerGeral = styled.div`
  width: 100%;
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 1em;
  row-gap: 1rem;
  `
export const CardObras = styled.div`
    display: flex;
    flex-direction: column;
    border: 0.1rem solid #A0a1A4;
    border-top: hidden;
    border-left: hidden;
    border-right: hidden;
    padding: 2rem;
    background: #f9f9f9;
    column-gap: 0.3rem;
    align-items: center;

    @media ${device.mobileS} {
        width: 90%;
        padding: 0.5rem;
        margin-left: 0.5rem;
        margin-top: 1rem;
    }

    @media ${device.mobileM} {
        width: 70%;
        padding: 1rem;
        column-gap: 2rem;
        row-gap: 0.7rem;
        margin: 0;
    }

    @media ${device.tablet} {
        width: 75%;
        margin-left: 2rem;
    } 

    @media ${device.laptop} {
        width: 100%;
        margin-top: 5%;
    }

    @media ${device.laptopL} {
        width: 100%;
        margin-top: 5%;
    }
`

export const CardApsgeral = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    row-gap: 1rem;
    column-gap: 1rem;
    align-items: center;
    border: 0.1rem solid #A0a1A4;
    border-top: hidden;
    border-right: hidden;
    border-left: hidden;
    padding: 2rem;
    background: #f9f9f9;
`

export const CardAps = styled.div`
    width: 30%;
    row-gap: 1rem;
    align-items: center;
  

    @media ${device.mobileS} {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.2rem;
        column-gap: 0.5rem;
    }

    @media ${device.mobileM} {
        width: 80%;
    }


    @media ${device.tablet} {
        width: 90%;
        padding: 2rem;
        column-gap: 2rem; 
    } 
`

export const CardCentraliza = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Linha = styled.hr`
    width: 100%;
`

export const ContainerPorcentagem = styled.div`
    display: flex;
    column-gap: 2rem;
`

export const ContainerChecklist = styled.div`
    gap: 0.8rem;
    display: flex;
    flex-direction: row;    
    font-family: 'Roboto';
    font-weight: 500;
    margin: 0.5rem;
    color: #485577ff;
    /* max-height: 150px; */

    @media ${device.mobileS}{
        flex-wrap: wrap;
        width: 75%;
        padding: 0.2rem;
        font-size: 1.1rem;
    }

    /* @media ${device.mobileM}{
        width: 66%;
    } */

    /* @media ${device.tablet}{
        width: 95%;
        height: 60px;
    } */

    @media ${device.laptop}{
        display: flex;
        flex-direction: row;
        padding: 1.5rem;
        width: 50%;
    }
`
export const InputChecklist = styled.input`
    margin: 0.5rem;
`

export const FormFoto = styled.form`


   @media ${device.mobileS}{
        display: flex;
        flex-direction: column;
        width: 100%;

        button{
            margin-left: 0%;
        }

        input{
            margin-left: 0%;
            margin-bottom: 5%;
            margin-top: 5%;
        }
    }

    @media ${device.tablet}{
        button{
            margin-left: 40%;
            width: 20%;
        }
        input{
            margin-left: 25%;
            margin-bottom: 5%;
            margin-top: 5%;
        }
    }

    @media ${device.laptop}{
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 2rem;

        button{
            margin-left: 40%;
        }

        input{
            margin-top: 1rem;
            margin-left: 30%;
        }
    }
`
export const FotosAps = styled.div`
    position: relative;
    margin-top: 1rem;
    gap: 0.7rem;
    width: 90%;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    background: #f9f9f9;
   

    @media ${device.mobileS}{
        display: flex;
        justify-content: center;

        img{
        max-width: 150px;
        width: 100%;
        border-radius: 10px;
        height: auto;
        }
      
        margin-bottom: 5%;
        margin-top: 5%;
    }

    @media ${device.tablet}{
        justify-content: center;
        
    }

    @media ${device.laptop}{
        img{
         max-width: 250px;
         width: 100%;
        }
       
    }
`
export const MarcaDAgua = styled.div`
    position: absolute;
    margin-top: 2%;
    font-size: 0.7rem;
    color: white;
    text-shadow: black 0.1em 0.1em 0.2em
`