import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../Constants/url";
import { Button } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CircularProgress from '@mui/material/CircularProgress';
import { goToAdminPage } from "../Routes/RouteFunctions";
// import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { Header } from "../Constants/Header";
import { CardApsgeral, CardCentraliza, CardObras, ContainerGeral, ContainerPorcentagem, Linha, MarcaDAgua, FotosAps } from "../Styled/StyledAdm/StyledInfoAdm";
import { memo, useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import { useProtectedPage } from "../Hooks/useProtectedPage";
import useForm from "../Hooks/useForm";
import Swal from 'sweetalert2';
import axios from "axios";

function InfoAdmPage() {

    useProtectedPage();
    const navigate = useNavigate();
    const { type, obra_id } = useParams();
    const [toggleNomeObra, setToggleNomeObra] = useState(false);
    const [toggleAndar, setToggleAndar] = useState(false);
    const [toggleApAndar, setToggleApAndar] = useState(false);
    const [toggleResponsavel, setToggleResponsavel] = useState(false);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [erroEdit, setErroEdit] = useState(false);
    const [data5, setData5] = useState(undefined)
    const [data2, setData2] = useState(false)
    const [data3, setData3] = useState([])
    const [image, setImage] = useState([])
    const [cont, setCont] = useState(false)
    const [form, handleInputChange, clear] = useForm({
        nome_obra: "", qty_andares: "", qty_ap_andar: "", responsavel: ""
    });

    let total = '';
    let apLimpGrossa = [];
    let apLimpFina = [];
    let apConcluded = [];
    let apConcluded2 = [];
    let apLimpFina2 = [];
    let apLimpGrossa2 = [];

    console.log('limpeza', apLimpFina)
    const GetInfo = () => {

        const token = localStorage.getItem("token");

        setLoadingEdit(true)

        axios.get(`${BASE_URL}/construction/info/${obra_id}`, {
            headers: {
                authorization: token
            }
        }).then((response) => {
            setLoadingEdit(false)
            setData5(response.data.apartments)
            // console.log(response.data)

        }).catch((error) => {
            setLoadingEdit(false)
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: '<a href="#">Why do I have this issue?</a>'
              });
            // console.log(error.response);

        });

    }

    useEffect(() => {
        GetInfo()
    }, [data2])

    const inf = !!data5 ? data5 : []
    const info = inf

    const arrayLimpeza = (limpeza_completa) => {
        if (limpeza_completa === 1) {
            apLimpGrossa2 = [...apLimpGrossa, limpeza_completa]
            apLimpGrossa = apLimpGrossa2
        } else if (limpeza_completa === 2) {
            apLimpFina2 = [...apLimpFina, limpeza_completa]
            apLimpFina = apLimpFina2
        } else if (limpeza_completa === 3) {
            apConcluded2 = [...apConcluded, limpeza_completa]
            apConcluded = apConcluded2
        }
    };

    const funcLimpeza = (limpeza) => {
        if (limpeza === 1) {
            return "Limpeza Grossa"
        } else if (limpeza === 2) {
            return "Limpeza Fina"
        } else if (limpeza === 3) {
            return "Pronto para Entrega"
        }
    };

    const editInput = (nome) => {
        return <TextField fullWidth required
            id="outlined-required"
            type={'text'}
            name={nome}
            value={form.nome}
            onChange={handleInputChange}
        />
    }


    const sendForm = async () => {
        // setLoadingEdit(true)
        const token = localStorage.getItem("token");

        axios.put(`${BASE_URL}/construction/editConstruction/${obra_id}`, form, {
            headers: {
                authorization: token
            }
        }).then(() => {
            // setLoadingEdit(false);
            alert("Obra Editada")
            setToggleAndar(false)
            setToggleApAndar(false)
            setToggleNomeObra(false)
            setToggleResponsavel(false)
            setData2(!data2)
        }).catch((error) => {
            setErroEdit(error.response);
            alert("Obra não pode ser Editada!")
            setLoadingEdit(false);
        });
    };

    const ListImage = async (obra_id) => {
        // console.log(id)
        axios.get(`${BASE_URL}/construction/apFoto/${obra_id}`)
            .then((response) => {
                setData3(response.data.result)
                // console.log(response.data)

            })
            .catch((error) => {
                console.log(error.message)
            })
    }

    const setaNomeobra = () => {
        setToggleNomeObra(!toggleNomeObra)
    };

    const setaAndar = () => {
        setToggleAndar(!toggleAndar)
    };

    const setaApAndar = () => {
        setToggleApAndar(!toggleApAndar)
    };

    const setaResponsavel = () => {
        setToggleResponsavel(!toggleResponsavel)
    }

    const listaObra = () => {
        return <CardObras>
            <h3>{info.nome_obra} <span>
                {type === 'admin' ?
                    <EditIcon fontSize="small" sx={{ color: '#1D2854ff' }} onClick={() => setaNomeobra()} /> : ''}
                {toggleNomeObra ? editInput("nome_obra") : ""}</span>
            </h3>
            <h4><strong>Total de andares: </strong> {info.qty_andares}<span>
                {type === 'admin' ?
                    <EditIcon fontSize="small" sx={{ color: '#1D2854ff' }} onClick={() => setaAndar()} /> : ''}
                {toggleAndar ? editInput("qty_andares") : ""}</span>
            </h4>
            <h4><strong>Apartamentos por andar: </strong> {info.qty_ap_andar} <span>
                {type === 'admin' ?
                    <EditIcon fontSize="small" sx={{ color: '#1D2854ff' }} onClick={() => setaApAndar()} /> : ''}
                {toggleApAndar ? editInput("qty_ap_andar") : ""}</span>
            </h4>
            <h4><strong>Total de apartamentos: </strong> {total = info.qty_ap_andar * info.qty_andares}</h4>
            {/* <h4><strong>Status Limpeza: </strong> {info.limpeza_completa} </h4> */}
            <h4><strong>Tipo de contrato: </strong> {info.responsavel}<span>
                {type === 'admin' ?
                    <EditIcon fontSize="small" sx={{ color: '#1D2854ff' }} onClick={() => setaResponsavel()} /> : ''}
                {toggleResponsavel ? editInput("responsavel") : ""}</span></h4>
            {type === 'admin' ?
                <Button type={"submit"} sx={{background: '#0000FF'}} variant="contained" size="small" sx={{ height: 20 }}
                    onClick={() => sendForm()} >salvar</Button> : ''}
        </CardObras>
    };


    const Aux = (id) => {
        ListImage(id)
        setCont(!cont)
    }

    const url1 = !!data3 ? data3 : []
    const url2 = url1

    const setAp = new Set();

    const ListInfos = data5 && data5.apartamentos.filter((info) => {
        { arrayLimpeza(info.limpeza_completa) }
        const duplicatedAp = setAp.has(info.ap_id);
        setAp.add(info.ap_id);
        return !duplicatedAp;
    }).map((info) => {
        return <CardCentraliza key={info.id}>
            <h4>Apartamento: {info.numero_ap}</h4>
            <p>Andar: {info.andar}</p>
            <CardApsgeral>
                {cont === true ? url2.map((a) => {
                    return <FotosAps>
                        <MarcaDAgua>
                            <h4>{funcLimpeza(info.limpeza)} ({info.data})</h4>
                        </MarcaDAgua>
                        <img src={`${BASE_URL}/files/users/${a}`} />
                    </FotosAps>
                }) : <div><Button size="small" variant="contained" sx={{ height: 20, background:'#0000FF'}} onClick={() => Aux(info.ap_id)}>Ver Fotos</Button></div>}
            </CardApsgeral >
        </CardCentraliza>
    });

    return (
        <ContainerGeral>
            <Header />
            <Button variant="contained" startIcon={<ArrowBackIosIcon />} onClick={() => goToAdminPage(navigate, type)}>Voltar</Button>
            {listaObra()}
            <CardCentraliza>
                <p><strong> Token do Cliente: <br />
                </strong>{obra_id}</p>
                <Linha></Linha>
                <p><strong>Faltam {total - apConcluded.length} apartamentos para entrega</strong></p>
                <Linha></Linha>
                {ListInfos}
                <ContainerPorcentagem>
                    <p><strong>Limpeza fina:</strong> {((apLimpFina.length / total) * 100).toFixed(1)}%</p>
                    <p><strong>Limpeza Grossa:</strong> {((apLimpGrossa.length / total) * 100).toFixed(1)}%</p>
                    <p><strong>Entrega:</strong> {((apConcluded.length / total) * 100).toFixed(1)}%</p>
                </ContainerPorcentagem>
                <Linha></Linha>
                <ContainerPorcentagem>
                    <p><strong>Porcentagem entrega semanal:</strong> {((apConcluded.length / total) / 7 * 100).toFixed(1)}%</p>
                    <p><strong>Porcentagem entrega mensal:</strong> {((apConcluded.length / total) / 30 * 100).toFixed(1)}%</p>
                    </ContainerPorcentagem>
                    <p>*Caso esteja 0%, ainda não há apartamentos prontos para entrega</p>
                
                <Linha></Linha>
            </CardCentraliza>
            {loadingEdit && loadingEdit &&
                <CircularProgress sx={{ color: '#4498C6ff' }} spacing={4} /> && <div>Carregando...</div>}
        </ContainerGeral>
    )
}

export default memo(InfoAdmPage)