import axios from "axios";
import { BASE_URL } from "../Constants/url";
import { goToAdminPage, goToClientPage, goToCollabPage, goToInfoClientPage, goToLoginPage } from "../Routes/RouteFunctions";
import Swal from "sweetalert2";

export const LoginClient = (body, navigate) => {
    let userData = {}
    // console.log(body)
    axios
        .post(`${BASE_URL}/user/client`, body

        )
        .then((response) => {
            
            userData = response.data
            localStorage.setItem('token', userData.sendKey.token)
            localStorage.setItem('obra_id', userData.sendKey.construction[0].id)
            // console.log(userData.sendKey.construction[0].id)
            const id = localStorage.getItem('obra_id')
            goToClientPage(navigate, 'client', id)
            
                
        }).catch((error) => {
            console.log(error.message)
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Login não encontrado!"
              });
            // alert("Login não encontrado", error.message)
        });
};
