import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../Constants/url";
import { useRequestData, useRequestDataCollab, } from "../Hooks/UseRequestData";
import { Button } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CircularProgress from '@mui/material/CircularProgress';
import { goToCollabPage, goToDeleteApPage, goToEditPage } from "../Routes/RouteFunctions";
// import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { Header } from "../Constants/Header";
import { CardAps, CardApsgeral, ContainerGeral, ContainerChecklist, InputChecklist, FotosAps, MarcaDAgua} from "../Styled/StyledCollab/StyledInfoPage";
import { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import { useProtectedPage } from "../Hooks/useProtectedPage";
import axios from "axios";
import Swal from "sweetalert2";


export const InfoPage = () => {

    useProtectedPage();
    const navigate = useNavigate();
    const { id, type, obra_id } = useParams();
    const [limpeza, setLimpeza] = useState('');
    const [cont, setCont] = useState(false)
    const [infos, loading, erro] = useRequestDataCollab(`${BASE_URL}/apartments/all/${id}/${obra_id}`);
    const [data3, setData3] = useState([]);
  
    const inf = !!infos ? infos : "carregando"
    const info = inf
    console.log(info)
    const [toggleLimpeza, setToggleLimpeza] = useState(false);

    const ListImage = async (id) => {
        console.log(id)
        axios.get(`${BASE_URL}/construction/apFoto/${id}`)
            .then((response) => {
                setData3(response.data.result)
            })
            .catch((error) => {
                console.log(error.message)
            })
    };

    const Aux = (id) => {
        ListImage(id)
        setCont(!cont)
    };

    const funcLimpeza = (limpeza_completa) => {
        if (limpeza_completa === 1) {
            return "Limpeza Grossa"
        } else if (limpeza_completa === 2) {
            return "Limpeza Fina"
        } else if (limpeza_completa === 3) {
            return "Pronto para Entrega"
        }
    };

    const setaLimpeza = () => {
        setToggleLimpeza(!toggleLimpeza)
    }

    const url1 = !!data3 ? data3 : []
    const url2 = url1

    // const setAp = new Set();

    const ListInfos = infos && infos.map((info) => {
        return <CardApsgeral key={info.id}>
            <h3>Apartamento: {info.numero_ap}</h3>
            <p><strong>Andar: </strong>{info.andar}</p>
            <p>Limpeza: {funcLimpeza(info.limpeza_completa)}
                <EditIcon fontSize="small" sx={{ color: '#1D2854ff' }} onClick={() => setaLimpeza(info.id)} />
                {toggleLimpeza ? goToEditPage(navigate, info.id) : ""}</p>
            <p>Data da limpeza: {info.data}</p>
            <p>Último Checklist:</p>
            <ContainerChecklist>
                {info.caxilho == 1 ? (<div><InputChecklist type='checkbox' checked /><p>Caxilho</p></div>) : (<div><InputChecklist type='checkbox' /><p>Caxilho</p></div>)}
                {info.paredes == 1 ? (<div><InputChecklist type='checkbox' checked /><p>Paredes</p></div>) : (<div><InputChecklist type='checkbox' /><p>Paredes</p></div>)}
                {info.pisos == 1 ? (<div><InputChecklist type='checkbox' checked /><p>Pisos</p></div>) : (<div><InputChecklist type='checkbox' /><p>Pisos</p></div>)}
                {info.tomadas == 1 ? (<div><InputChecklist type='checkbox' checked /><p>Tomadas</p></div>) : (<div><InputChecklist type='checkbox' /><p>Tomadas</p></div>)}
                {info.tetos == 1 ? (<div><InputChecklist type='checkbox' checked /><p>Tetos</p></div>) : (<div><InputChecklist type='checkbox' /><p>Tetos</p></div>)}
                {info.portas == 1 ? (<div><InputChecklist type='checkbox' checked /><p>Portas</p></div>) : (<div><InputChecklist type='checkbox' /><p>Portas</p></div>)}
                {info.banheiros == 1 ? (<div><InputChecklist type='checkbox' checked /><p>Banheiros</p></div>) : (<div><InputChecklist type='checkbox' /><p>Banheiros</p></div>)}
                {info.sacadas == 1 ? (<div><InputChecklist type='checkbox' checked /><p>Sacadas</p></div>) : (<div><InputChecklist type='checkbox' /><p>Sacadas</p></div>)}
                {info.bancadas == 1 ? (<div><InputChecklist type='checkbox' checked /><p>Bancadas</p></div>) : (<div><InputChecklist type='checkbox' /><p>Bancadas</p></div>)}
                {cont === true ? url2.map((info) => {
                    console.log('info', info)
                    return <FotosAps>
                            <MarcaDAgua>
                            <h4>{funcLimpeza(info.limpeza)} ({info.data})</h4>
                        </MarcaDAgua>
                        {/* <img src={`${BASE_URL}/files/users/${info.id}`} /> */}
                    </FotosAps>
                }) : <div><Button size="small" variant="contained" sx={{ height: 20, background: '#0000FF' }} onClick={() => Aux(info.ap_id)}>Ver Fotos</Button></div>}
            </ContainerChecklist>
        </CardApsgeral>
    });

  
    return (
        <ContainerGeral>
            <Header />
            <Button variant="contained" sx={{background: '#0000FF'}} startIcon={<ArrowBackIosIcon />} onClick={() => navigate(-1)}>Voltar</Button>
            {data3}
            {!loading && infos && <CardAps>{info.lenght == 10 || info.length == 0 ? "Você não possui apartamentos cadastrados nesta obra" : ListInfos}</CardAps>}
            {!loading && erro && <p>Deu ruim!</p>}
            {loading && loading &&
                <CircularProgress sx={{ color: '#4498C6ff' }} spacing={2} />}
        </ContainerGeral>
    )
};
