import { useState } from "react";
import { Button, Input } from "@mui/material";
import { Header } from "../../Constants/Header";
import { goToCollabPage } from "../../Routes/RouteFunctions";
import { useNavigate, useParams } from "react-router-dom";
import {ref, uploadBytes  } from "firebase/storage";
import { storage } from "../../Config/firebase";
import Swal from "sweetalert2";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SendIcon from '@mui/icons-material/Send';
import { ContainerCard, ContainerGeral, DivLabel, LabelArquivo, FormFoto, SomeInput, Image } from "../../Styled/StyledCollab/StyledConcludedAp";


export default function AddFoto() {

    const { obra_id, ape_id } = useParams();   
    const [images, setImages] = useState(null);
    const navigate = useNavigate();
    const user_id = localStorage.getItem('id')
    const type = localStorage.getItem('role')


    const onSelectedFiles = (e) => {
        e.preventDefault();
        // if (images === null) return;        

        const imageRef = ref(storage, `images/${obra_id}/${ape_id}/${images.name}`); //caminho onde serão salvas as imgs no firebase
              
        uploadBytes(imageRef, images).then(() => {  //requisição que envia a foto para o storage do firebase no caminho de cima
            // Swal.fire({
            //     icon: "succes",
            //     title: "Sucesso!",
            //     text: "Imagem Salva!"
            // });
            alert("imagem salva, adicione nova ou volte ao menu principal")
        }).catch((error)=>{
            console.error(error)
            // Swal.fire({
            //     icon: "error",
            //     title: "Oops...",
            //     text: "deu erro! Tente novamente"
            //   });
            alert("erro")
        })
    };

    return (
        <ContainerGeral>
            <Header />
            <Button variant="contained" startIcon={<ArrowBackIosIcon />} onClick={() => goToCollabPage(navigate, type, user_id)}>finalizar</Button>
            <ContainerCard>
                <FormFoto onSubmit={onSelectedFiles}>
                    <p>Adicione a foto desejada e clique em "add foto". Caso tenha mais de uma, repita o processo</p>
                    <DivLabel>
                        <LabelArquivo for="firebase-file">selecionar foto
                        <SomeInput id="firebase-file" type={"file"} onChange={(e) => { setImages(e.target.files[0]) }} />
                        </LabelArquivo>
                        <div>
                            <Image src={images}></Image>
                        </div>
                    </DivLabel>
                    <Button variant="contained" endIcon={<SendIcon />} type={"submit"} >add Foto</Button>
                </FormFoto>
            </ContainerCard>
        </ContainerGeral>
    )
}