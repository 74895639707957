import styled from "styled-components";
import { device } from "./Query";
import { gradienteAzul } from "../Styled/variaveis";

export const ContainerGeral = styled.div`
    display: flex;
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: 1em;
    width: 100%;
    /* height: ;  */
    position: relative;

   
    @media ${device.laptop} {
       align-items: flex-start;
       justify-content: flex-start;
    }
`

export const Image = styled.img`
    justify-content: center;
    top: 5%;
    margin-left: 15%;
    width: 15%;
    height: auto;
    position: absolute;
    margin-top: 0.2rem;

    @media ${device.mobileS} {
        width: 35%;
    }

    @media ${device.tablet} {
        width: 18%;
    }

    @media ${device.laptop} {
        width: 9%;
    }
`

export const Linha = styled.hr`
    margin-top: 6rem;
    color: #f00;
    background: ${gradienteAzul};
    width: 150rem;

    @media ${device.mobileS} {
        height: 2rem; 
    }

    @media ${device.tablet} {
        height: 3rem; 
    }
`
