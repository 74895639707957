import { NavLink, useNavigate, useParams } from "react-router-dom";
import LoginForm from "./LoginForm";
import { Header } from "../../Constants/Header";
import { goToHomePage, goToSignUpPage } from "../../Routes/RouteFunctions";
import { ContainerGeral, Registrar } from "../../Styled/StyledLogin/StyledloginPage";
import useUnprotectedPage from "../../Hooks/useUnprotectedPage";

export default function LoginPage() {
    const { type } = useParams();
    useUnprotectedPage(type)
    const navigate = useNavigate();

    // console.log('type np loginpage', type)

    return (
        <ContainerGeral>
            <Header
                role={type}
            />
                <LoginForm />
                <br />
                {/* <Registrar onClick={() => goToSignUpPage(navigate, type)}>Registrar-se</Registrar>  */}
        </ContainerGeral>
    )
}