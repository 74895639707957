import styled from "styled-components";
import { device } from "../../Constants/Query";

export const ContainerGeral = styled.div`
  width: 100%;
  height: 100vh;
  background-size: cover;
  display: flex;
  align-items: center;
  background-image: linear-gradient( to bottom, #1C284Fff, blue );
  justify-content: space-around;
  font-family: 'Open Sans';
  /* font-weight: 600; */
  font-size: 1em; 
`

export const Image = styled.img`
    width: 70%;
    height: auto;  
`

export const ContainerCard = styled.div`
    display: flex;
    flex-direction: column;
    /* border-radius: 0.2rem solid #1C284Fff; */
    border-radius: 0.8rem;
    padding: 2rem;
    gap: 0.5rem;
    background: white;
    align-items: center;

    @media ${device.mobileS} {
        margin: 0.5rem;
        width: 75%;
    }

    @media ${device.tablet} {
        width: 70%;
    }

    @media ${device.laptop} {
        width: 40%;
    }
`
export const ContainerButton = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0.8rem;
    align-items: center;
  

    @media ${device.mobileS} {
         width: 115%;
         column-gap: 0.1rem;
    }

    @media ${device.mobileM} {
         width: 100%;
         column-gap: 0.2rem;
    }

    @media ${device.tablet}{
        width: 80%;
        column-gap: 0.2rem;
    }
`

// export const CustomizeButton = styled(Button)({
//     ontSize: 16,
//     padding: '6px 12px',
//     border: '1px solid',
//     lineHeight: 1.5,
//     backgroundColor: '#0063cc',
//     borderColor: '#0063cc',

// export const BotaoSmall = styled(Button)`
    
// `
    