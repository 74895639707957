import styled from "styled-components";
import { device } from "../../Constants/Query";
import { cinzaClaro, cinzaEscuro } from "../variaveis";

export const ContainerGeral = styled.div`
  width: 100%;
  min-width: 250px;
  max-width: 1800vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 1em;
  row-gap: 0.6rem;

  @media ${device.mobileS}{
    width: 100%;
  }

  @media ${device.tablet}{
    width: 70%;
  }

  @media ${device.laptop}{
     width: 100%;
  }
   `

export const CardObras = styled.div`
    display: flex;
    flex-direction: row;
    border: 0.1rem solid #A0a1A4;
    border-top: hidden;
    border-left: hidden;
    border-right: hidden;
    padding: 2rem;
    background: #f9f9f9;
    column-gap: 0.3rem;
    align-items: center;
    left: 0.5rem;
    color: ${cinzaEscuro};

    @media ${device.mobileS} {
        width: 90%;
        padding: 0.5rem;
        margin-left: 0.5rem;
        margin-top: 1rem;
    }

    @media ${device.mobileM} {
        width: 70%;
        padding: 1rem;
        column-gap: 2rem;
        row-gap: 0.7rem;
        margin: 0;
    }

    @media ${device.tablet} {
        width: 50%;
        margin-top: 3%;
    } 

    @media ${device.laptop} {
        width: 90%;
        margin-top: 2%;
    }
`

export const CardApsgeral = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 90%;
    align-items: center;
    justify-items: center;
    border: 0.1rem solid #A0a1A4;
    border-top: hidden;
    border-right: hidden;
    border-left: hidden;
    padding: 1rem;
    background: ${cinzaClaro};
       

    @media ${device.laptop}{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        
    }
`

export const CardAps = styled.div`
    width: 30%;
    row-gap: 1rem;
    align-items: center;
  

    @media ${device.mobileS} {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.2rem;
        column-gap: 0.5rem;
    }

    @media ${device.mobileM} {
        width: 80%;
    }


    @media ${device.tablet} {
        width: 100%;
        padding: 2rem;
        column-gap: 2rem; 
    } 

    @media ${device.laptop} {
        width: 100%;
        padding: 2rem;
        column-gap: 2rem; 
        display: grid;
    } 
`

export const CardCentraliza = styled.div`
    max-width: 80%;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 0.1rem;
    column-gap: 0.8rem;
    align-items: center;
    justify-content: center;
`

export const GridAptos = styled.div`
    border: 0.1rem;
    background-color: ${cinzaClaro};
    padding: 0.5rem;
    border-radius: 10px;
    text-align: center;
`

export const Linha = styled.hr`
    width: 100%;
`

export const ContainerPorcentagem = styled.div`
    column-gap: 2rem;

    @media ${device.mobileM}{
        display: flex;
        flex-direction: column;
    }
    @media ${device.laptop}{
        display: flex;
        flex-direction: row;
    }

`

export const ImageContainer = styled.div`
    margin-top: 1rem;
    gap: 0.7rem;
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`

export const FotosAps = styled.div`
    position: relative;
    margin-top: 1rem;
    gap: 0.7rem;
    width: 90%;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    background: ${cinzaClaro};
   

    @media ${device.mobileS}{
        display: flex;
        justify-content: center;

        img{
        max-width: 150px;
        width: 100%;
        border-radius: 10px;
        height: auto;
        }
      
        margin-bottom: 5%;
        margin-top: 5%;
    }

    @media ${device.tablet}{
        justify-content: center;
        
    }

    @media ${device.laptop}{
        img{
         max-width: 250px;
         width: 100%;
        }
       
    }
`

export const MarcaDAgua = styled.div`
    position: absolute;
    margin-top: 2%;
    font-size: 0.7rem;
    color: white;
    text-shadow: black 0.1em 0.1em 0.2em
`