import styled from "styled-components";

export const ContainerGeral = styled.div`
    width: 100%;
    min-height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: 1em;
    /* background-image: linear-gradient( to bottom, #1C284Fff, blue ); */
    justify-content: center;
  `

// export const RegistroGeral = styled.div`
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     width: 100%;
// `
export const Registrar = styled.a`
  cursor: pointer;
`