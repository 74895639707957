import { useNavigate } from "react-router-dom";
import logo from "../images/logo-sem fachadas.jpg";
import { goToHomePage } from "../Routes/RouteFunctions";
import { ContainerGeral, Image, Linha } from "./StyledHeader";

export const Header = () => {

    const navigate = useNavigate();

    return (
        <ContainerGeral>
           <Image src={logo} alt="logo" onClick={()=>goToHomePage(navigate)}/>
            <Linha></Linha>
        </ContainerGeral>
    )
};