import axios from "axios";
import { BASE_URL } from "../Constants/url";
import { goToAdminPage, goToCollabPage, goToLoginPage, goToClientPage } from "../Routes/RouteFunctions";
import Swal from 'sweetalert2';


export const Login = (body, navigate) => {
    let userData = {}
    console.log(body)
    axios
        .post(`${BASE_URL}/user/login`, body

        )
        .then((response) => {
            
            userData = response.data
            localStorage.setItem('token', userData.sendKey.token)
            localStorage.setItem('id', userData.sendKey.id)
            localStorage.setItem('role', userData.sendKey.role)

            // console.log(userData)


            if (userData === false) {
                goToLoginPage(navigate)
            } else if (userData.sendKey.role === 'admin') {
                goToAdminPage(navigate, userData.sendKey.role, userData.sendKey.id)
            } else if (response.data.sendKey.role === 'collab') {
                goToCollabPage(navigate, userData.sendKey.role, userData.sendKey.id)
            } else if (response.data.sendKey.role === 'client') {
                goToClientPage(navigate, userData.sendKey.role, userData.sendKey.id)   
            }

        }).catch((error) => {
            console.log(error.message)
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Login não encontrado!"
              });
        });
};
