import styled from "styled-components";
import { device } from "../../Constants/Query";


export const ContainerGeral = styled.div`
  width: 100%;
  height: 96vh;
  /* background-size: cover; */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 1em;

  @media ${device.mobileS}{
        margin-top: 2rem;
 }

  @media ${device.laptop}{
        /* margin-top: 2rem; */
 }

  @media ${device.laptopL}{
    /* justify-content: space-around; */
}
`

export const CardGeralFotos = styled.div`
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.2rem;
    margin: 1rem;
    /* align-content: center; */
`

export const FotosAps = styled.span`
    display: flex;
    
`

export const Images = styled.img`
    position: relative;
    margin-top: 1rem;
    gap: 0.7rem;
    margin: 1rem;

    @media ${device.mobileS}{
        
        max-width: 150px;
        width: 100%;
        border-radius: 10px;
        height: auto;
        }
      
      
    @media ${device.laptop}{
         max-width: 250px;
         width: 100%;
   } 
`