import styled from "styled-components";
import { device } from "../../Constants/Query";

export const ContainerGeral = styled.div`
  min-width: 100%;
  min-height: 96vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 1em;

  @media ${device.mobileS}{
        margin-top: 2rem;
 }

  @media ${device.laptop}{
        margin-top: 2rem;
 }

  @media ${device.laptopL}{
    justify-content: space-around;
}
`

export const ContainerCard = styled.div`
    margin-top: 0.75rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    box-shadow: 2px 2px 2px 2px #d3d3d3;
    padding: 2.5rem;
    background: #f9f9f9;
    align-items: center;
    justify-items: center;

    @media ${device.mobileS}{
        margin-top: 2rem;
        padding: 1rem;
        width: 70%;
    }

    @media ${device.laptop}{
        margin-top: 2rem;
        width: 60%;
        padding: 2.5rem;
    }
`

export const ContainerChecklist = styled.div`
    gap: 0.8rem;
    font-family: 'Roboto';
    font-weight: 500;
    margin: 0.5rem;
    color: #485577ff;
    max-height: 150px;

    @media ${device.mobileS}{
        display: flex;
        flex-wrap: wrap;
        width: 80%;
        padding: 0.2rem;
        font-size: 1rem;
    }

    @media ${device.mobileM}{
        width: 65%;
        margin-bottom: 1.5rem;
        font-size: 0.9rem;
    }

    @media ${device.mobileL}{
        width: 70%;
        margin-bottom: 1.5rem;
        font-size: 0.9rem;
    }
  
    @media ${device.tablet}{
        width: 95%;
        height: 60px;
        font-size: 1.2rem;
    }

    @media ${device.laptop}{
        padding: 1rem;
        width: 80%;
        font-size: 1.2rem;
    }
`
export const InputChecklist = styled.input`
    margin: 0.5rem;
`

export const FormFoto = styled.form`


   @media ${device.mobileS}{
        display: flex;
        flex-direction: column;
        width: 100%;

        button{
            margin-left: 0%;
        }

        input{
            margin-left: 0%;
            margin-bottom: 5%;
            margin-top: 5%;
        }
    }

    @media ${device.tablet}{
        button{
            margin-left: 40%;
            width: 20%;
        }
        input{
            margin-left: 25%;
            margin-bottom: 5%;
            margin-top: 5%;
        }
    }

    @media ${device.laptop}{
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 2rem;

        button{
            margin-left: 40%;
        }

        input{
            margin-top: 1rem;
            margin-left: 30%;
        }
    }
`

export const ContainerUpload = styled.div`
    margin-top: 1rem;
       
    @media ${device.mobileS}{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    @media ${device.laptop}{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        height: auto;
    }
`
export const Image = styled.img`
        width: 100%;
        border-radius: 10px;
  `

export const ImageGrid = styled.div`
    gap: 0.8rem;
 
    @media ${device.mobileS}{
      display: flex;
      flex-direction: column;
    }

    @media ${device.tablet}{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

}
`