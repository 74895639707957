import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useForm from "../../Hooks/useForm";
import { Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PasswordIcon from '@mui/icons-material/Password';
import SendIcon from '@mui/icons-material/Send';
import { ContainerButton, ContainerCard, ContainerForm } from "../../Styled/StyledLogin/StyledLoginForm";
import { ContainerGeral, RegistroGeral } from "../../Styled/StyledLogin/StyledloginPage";
// import { BASE_URL } from "../../Constants/url";
// import axios from "axios";
import { goToInfoClientPage } from "../../Routes/RouteFunctions";
import { LoginClient } from "../../Hooks/useRequestClientLogin";
import { Header } from "../../Constants/Header";

export const LoginClientForm = () => {

    const { type } = useParams();
    // const [obra_id, setObraId] = useState('');
    const [form, handleInputChange] = useForm({ id: "" });
    const navigate = useNavigate();

    const sendForm = (e) => {
        e.preventDefault();
        LoginClient(form, navigate)
        goToInfoClientPage(navigate, type, form.id)
    };

    // console.log('type form',type)

    return (
        <ContainerGeral>
        <Header
            role={type}
        />
        <ContainerForm onSubmit={sendForm}>
            <ContainerCard>
                <TextField fullWidth required
                    id="demo-helper-text-misaligned"
                    label="Insira token"
                    type="text"
                    autoComplete="current-password"
                    name={"id"}
                    value={form.id}
                    onChange={handleInputChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PasswordIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <ContainerButton>
                    <Button variant="contained" sx={{background: '#0000FF'}} endIcon={<SendIcon />} type={"submit"}>Entrar</Button>
                </ContainerButton>
            </ContainerCard>
        </ContainerForm>
        </ContainerGeral>

    )
};