import styled from "styled-components";
import { device } from "../../Constants/Query";

export const ContainerGeral = styled.div`
  width: 100%;
  min-height: auto;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 1em;
`

export const AlinhaSaudacao = styled.div`
    display: grid;
    color: #0000ff;
    font-family: 'Open Sans';
    font-weight: 800;
    font-size: 2em;
`

export const ContainerLogout = styled.div`
    display: flex;
    flex-direction: row;
    gap: 7rem;
    margin-top: 1rem;
`

export const ContainerGrid = styled.div`

@media ${device.mobileS} {
    display: flex;
    flex-direction: column;
    width: 45%;
    gap: 1.5rem;
    } 
    
    @media ${device.mobileM} {
      width: 55%;
      gap: 1.5rem;
    } 

  @media ${device.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 45%;
    gap: 2rem;
    } 

  @media ${device.laptop} {
        width: 70%;
        gap: 5rem;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-top: 5%;
    }
`

export const CardObras = styled.div`
    width: 90%;
    margin-top: 0.85rem;
    display: flex;
    flex-direction: column;
    box-shadow: 10px 5px 5px #d3d3d3;
    border-radius: 10px;
    background: #f9f9f9;
    padding: 2rem;
    column-gap: 0.1rem;
    justify-content: space-around;

    @media ${device.mobileS} {
     border: 0.1rem solid #c1c1c3 ;
     border-right: hidden;
     border-left: hidden;
     border-top: hidden;
     padding: 0.5rem;
    } 
`

export const ContainerText = styled.div`
    display: flex;
    justify-content: center;
`

export const ContainerButton = styled.div`
    display: flex;
    justify-content: space-around;
    padding-bottom: 2rem;
`